/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCameraKit } from "./hooks/useCameraKit";
import {
  createMediaStreamSource,
  Transform2D,
  Lens as SnapLens,
} from "@snap/camera-kit";
import "./App.css";
import "./styles/MediaQueries.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

interface Lens {
  name: string;
  iconUrl: string;
  preview: {
    imageUrl: string;
  };
}

function App() {
  const { session, lenses } = useCameraKit();
  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const [cameraList, setCameraList] = useState<MediaDeviceInfo[]>([]);
  const [selectedCamera, setSelectedCamera] = useState<string>("");
  const [selectedLens, setSelectedLens] = useState<SnapLens | null>(null);
  const [isPortrait, setIsPortrait] = useState<boolean>(true);
  const [isMirrored, setIsMirrored] = useState<boolean>(false);
  const carouselRef = useRef<HTMLDivElement>(null);
  const [isLensLoading, setIsLensLoading] = useState(false);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const [rotationAngle, setRotationAngle] = useState(0);

  // Estado para almacenamiento en caché de lentes
  const lensCacheRef = useRef<Map<string, SnapLens>>(new Map());
  const memoizedLenses = useMemo(() => lenses, [lenses]);

  const startCameraKit = useCallback(async () => {
    try {
      if (!mediaStreamRef.current) {
        mediaStreamRef.current = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: selectedCamera },
        });
      }

      const source = createMediaStreamSource(mediaStreamRef.current, {
        transform: isMirrored ? Transform2D.MirrorX : undefined,
      });

      session.setSource(source);
      session.applyLens(selectedLens || lenses[0]);
      session.play("live");

      const width = isPortrait ? window.innerWidth : window.innerHeight;
      const height = isPortrait ? window.innerHeight : window.innerWidth;
      await source.setRenderSize(width, height);
    } catch (error) {
      console.error("Error en startCameraKit:", error);
    }
  }, [session, selectedCamera, selectedLens, lenses, isPortrait, isMirrored]);

  const capturePhoto = (lens: SnapLens) => {
    const originalCanvas = session.output.live;
    const lensName = lens.name.replace(/\s+/g, "-");

    const newCanvas = document.createElement("canvas");
    newCanvas.width = window.innerWidth / 2;
    newCanvas.height = window.innerHeight;
    const ctx = newCanvas.getContext("2d");

    if (ctx) {
      ctx.drawImage(originalCanvas, 0, 0, newCanvas.width, newCanvas.height);

      newCanvas.toBlob((blob) => {
        if (blob) {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${lensName}.png`;
          a.click();
          URL.revokeObjectURL(url);
        }
      });
    }
  };

  const handleLensChange = async (lensId: string) => {
    const lens = lenses.find((lens) => lens.id === lensId);
    if (lens) {
      if (selectedLens?.id === lensId) {
        capturePhoto(lens);
        console.log("Hola", lens);
      } else {
        setIsLensLoading(true);
        setSelectedLens(lens);

        // Verificar si el lente ya está en caché
        if (!lensCacheRef.current.has(lensId)) {
          try {
            await session.applyLens(lens);
            // Almacenar el lente en caché
            lensCacheRef.current.set(lensId, lens);
          } catch (error) {
            console.error("Error al aplicar el lente:", error);
          }
        } else {
          // Si el lente está en caché, simplemente aplicarlo
          session.applyLens(lensCacheRef.current.get(lensId)!);
        }

        setIsLensLoading(false);

        // Guardar los lentes en un archivo JSON
        saveLensesToJson(lenses);

        const selectedItem = document.getElementById(lensId);
        selectedItem?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };

  // Función para guardar los lentes en un archivo JSON
  const saveLensesToJson = (lenses: any) => {
    const json = JSON.stringify(lenses, null, 2); // Convertir a JSON
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "lenses.json"; // Nombre del archivo
    document.body.appendChild(a);
    // a.click(); // Simular un clic para descargar
    document.body.removeChild(a); // Limpiar el DOM
    URL.revokeObjectURL(url); // Liberar la URL
  };

  // const loadLenses = async (filePath:any) => {
  //     try {
  //         const response = await fetch(filePath);
  //         if (!response.ok) {
  //             throw new Error('Error al cargar el archivo JSON');
  //         }
  //         const lenses = await response.json();
  //         return lenses;
  //     } catch (error) {
  //         console.error('Error al cargar los lentes:', error);
  //         return [];
  //     }
  // };

  const loadLenses = async (filePath: string): Promise<Lens[]> => {
    try {
      const response = await fetch(filePath);
      if (!response.ok) {
        throw new Error("Error al cargar el archivo JSON");
      }
      const lenses: Lens[] = await response.json();
      return lenses;
    } catch (error) {
      console.error("Error al cargar los lentes:", error);
      return [];
    }
  };

  const saveLensesToPublic = (lenses: any) => {
    const publicDir =
      "/Users/prospektiva/Documents/Prospektiva/Code/FranCam-Project/public/"; // Ajusta la ruta según tu estructura
    // fs.writeFileSync(publicDir, JSON.stringify(lenses, null, 2), 'utf-8');
    console.log("Lentes guardados en:", publicDir);
  };

  // Uso de la función
  loadLenses("../lenses_two.json").then((lenses) => {
    console.log("Lentes cargados:", lenses);
    lenses.forEach((lens) => {
      console.log("Lente:", lens.name);
      console.log("Icono:", lens.iconUrl);
      console.log("Preview:", lens.preview.imageUrl);
    });
    // Guardar lentes en la carpeta pública
    saveLensesToPublic(lenses);
  });

  // const loadLenses = async () => {
  //     try {
  //         const response = await fetch('../lenses.json');
  //         if (!response.ok) {
  //             throw new Error('Error al cargar el archivo JSON');
  //         }
  //         const lenses = await response.json();
  //         return lenses;
  //     } catch (error) {
  //         console.error('Error al cargar los lentes:', error);
  //         return [];
  //     }
  // };

  useEffect(() => {
    if (memoizedLenses.length > 0) {
      const defaultLens = memoizedLenses[0];
      setSelectedLens(defaultLens);
      session.applyLens(defaultLens);
    }
  }, [memoizedLenses, session]);

  useEffect(() => {
    const getCameras = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const cameras = devices.filter(({ kind }) => kind === "videoinput");
      setCameraList(cameras);

      if (cameras.length > 0) {
        setSelectedCamera(cameras[0].deviceId);
      }
    };

    getCameras();
    startCameraKit();
  }, [startCameraKit]);

  useEffect(() => {
    if (canvasContainerRef.current) {
      canvasContainerRef.current.replaceWith(session.output.live);
      const canvas = session.output.live;

      const resizeCanvas = () => {
        if (isPortrait) {
          canvas.style.width = `${window.innerWidth}px`;
          canvas.style.height = `${window.innerHeight}px`;
        } else {
          canvas.style.width = `${window.innerHeight}px`;
          canvas.style.height = `${window.innerWidth}px`;
        }
      };

      resizeCanvas();
      window.addEventListener("resize", resizeCanvas);

      return () => {
        window.removeEventListener("resize", resizeCanvas);
      };
    }
  }, [session, isPortrait]);

  // const toggleOrientation = () => {
  //   setIsPortrait(!isPortrait); // Alterna entre vertical y horizontal
  // };

  const toggleMirror = () => {
    setIsMirrored((prev) => !prev);
  };

  const handleScroll = () => {
    if (isLensLoading) return;

    const carousel = carouselRef.current;
    if (!carousel) return;

    const carouselRect = carousel.getBoundingClientRect();
    const carouselCenterX = carouselRect.left + carouselRect.width / 2;

    const items = carousel.querySelectorAll(".carousel-item");

    items.forEach((item) => {
      const itemRect = item.getBoundingClientRect();
      const itemCenterX = itemRect.left + itemRect.width / 2;
      const distanceFromCenter = Math.abs(itemCenterX - carouselCenterX);
      const scale = Math.max(1 - distanceFromCenter / 500, 0.75);
      (item as HTMLElement).style.transform = `scale(${scale})`;
    });
  };

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.addEventListener("scroll", handleScroll);
      handleScroll();

      return () => {
        carousel.removeEventListener("scroll", handleScroll);
      };
    }
  }, [lenses]);

  const handleRotateRight = () => {
    setRotationAngle((prevAngle) => prevAngle + 90); // Gira 90 grados más a la derecha
  };

  // Nueva función para manejar las teclas presionadas
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "c" || event.key === "C") {
      handleRotateRight();
    }
  };

  useEffect(() => {
    // Agregar el manejador de eventos para las teclas
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      // Limpiar el manejador de eventos al desmontar
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Container fluid> 
      <Row>
        <Col xs={12} md={12} lg={12}>
          <div className="main-container">
            <div
              className="rotate-container"
              style={{
                transform: `rotate(${rotationAngle}deg)`,
                transition: "transform 0.3s ease",
              }}
            >
              <div className="canvas-container" ref={canvasContainerRef}></div>
            </div>
            <div className="carousel-container">
              <div
                className={!isPortrait ? "carousel-portrait" : "carousel"}
                ref={carouselRef}
              >
                {lenses.map((lens) => (
                  <div
                    id={lens.id}
                    key={lens.id}
                    className={`carousel-item ${
                      selectedLens?.id === lens.id ? "active" : ""
                    }`}
                    onClick={() => handleLensChange(lens.id)}
                  >
                    <img
                      src={lens.iconUrl}
                      alt={lens.name}
                      className="lens-preview"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="powered-by">
              <span>Powered by</span>
              <img src="/snapchat.png" alt="Snap Icon" className="snap-icon" />
            </div>

            <button onClick={toggleMirror} className="mirror-button">
              <img
                src="/mirror-image.png"
                alt="Mirror Icon"
                className="mirror-icon"
              />
            </button>

            <button onClick={handleRotateRight} className="orientation-button">
              <img
                src="/orientation-icon.png"
                alt="Orientation Icon"
                className="orientation-icon"
              />
            </button>

            {/* <button className="capture-button">
          <i className="fas fa-camera"></i>
        </button> */}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
