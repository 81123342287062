import { bootstrapCameraKit, CameraKitSession, Lens } from '@snap/camera-kit';
import { createContext, useEffect, useRef, useState } from 'react';

//const apiToken = 'eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNzI0Njc5OTY1LCJzdWIiOiJiMjE4MjZjMy1iNTg0LTQ2M2MtOWI0Yy02OTZlMmJkZDdjMzZ-U1RBR0lOR341OWRjNWZmZS00ZDRmLTRmZDAtYWY4OS1hMmExYjQ1ODgzNjQifQ.oL2GMyVBCp8fxFJrABVZNIoAyGRzaxBSYlqFPLnpdrs';

const apiToken = 'eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNzI4NDEzMTcyLCJzdWIiOiI3MDE1Njc5Mi05NWI1LTQxZWQtOTllYi02NDFiOGJlNWNhZTJ-U1RBR0lOR345ZGMxZjMyNi02YTM0LTRmMDctYjE2YS03Y2ZmOWQ0ZDBjMjgifQ.ymmBcGA9n_lEykzzD3rRKxYq2mF0qxSip2yy15z1RUw';
const lensGroupId = 'f7fa8128-b66c-4167-9eff-9a221a0357b5';

export interface CameraKitState {
  session: CameraKitSession;
  lenses: Lens[];
}

export const CameraKitContext = createContext<CameraKitState | null>(null);

export const CameraKit: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isInitialized = useRef<boolean>(false);
  const [session, setSession] = useState<CameraKitSession | null>(null);
  const [lenses, setLenses] = useState<Lens[]>([]);

  useEffect(() => {
    const initializeCameraKit = async () => {
      const cameraKit = await bootstrapCameraKit({ apiToken });
      const session = await cameraKit.createSession();
      const { lenses } = await cameraKit.lensRepository.loadLensGroups([
        lensGroupId,
      ]);

      setLenses(lenses);
      setSession(session);
    };

    if (isInitialized.current) return;
    isInitialized.current = true;

    initializeCameraKit();
  }, []);

  return !session ? (
    <div>Iniciando Fran Cam...</div>
  ) : (
    <CameraKitContext.Provider value={{ session, lenses }}>
      {children}
    </CameraKitContext.Provider>
  );
};